import * as React from "react";
import Layout from "../components/Layout";
// import VisibilitySensor from 'react-visibility-sensor'; Q: what for?

// markup
// const IndexPage = () => {

//   return (
//     <div>
//       <Layout></Layout>
//     </div>
//   )
// }

// export default IndexPage

//make it a class component:
class IndexPage extends React.Component {
  // componentDidMount(){
  //   // here attach all event handlers
  //   debugger;
  //   console.log('mounted');
  //   if (typeof window !== 'undefined'){
  //       window.addEventListener('hashchange', ()=>{
  //           debugger;
  //           //this._scrollToActiveSection();

  //       }, false);

  //       window.addEventListener('load', ()=>{
  //           debugger;
  //           //this._scrollToActiveSection();
  //       }, false);

  //       // window.addEventListener('scroll', ()=>{
  //       //     debugger;
  //       //     this._handleScroll();
  //       // });
  //   }
  // }

  render() {
    return (
      <div>
        <Layout></Layout>
      </div>
    );
  }
}

export default IndexPage;
